<template>
  <!--右侧带图标的输入框,长度限制为40个字符,禁止输入表情和某一些特殊元素 -->
  <div class="input_co_type" :style="{ width: inputOtion.inputWidth }">
    <div class="inputicon" @click="clearvalue" :style="{ height: inputOtion.inputHeight }">
      <img :src="inputOtion.iconUrl" />
    </div>
    <input
      :type="inputOtion.type"
      :placeholder="inputOtion.placeHolderTxt"
      v-model="inputvalue"
      class="input_type"
      :maxlength="inputOtion.maxlength || 40"
      @change="changeEvent"
      :style="{ height: inputOtion.inputHeight, width: inputOtion.inputWidth }"
    />
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      inputvalue: this.value,
    }
  },
  props: {
    inputOtion: {
      type: Object,
      default() {
        return {
          placeHolderTxt: '',
          iconUrl: '', //图标地址
          autoStorage: false, //是否自动缓存，该值设置为true时必须设置storagename的值
          storageName: '', //缓存变量存储的变量名称
          inputWidth: '100%',
          inputHeight: '40px',
          type: 'text',
          maxlength: 40,
        }
      },
    },
    value: {
      type: String,
      default: 'text',
    },
  },
  mounted: function () {
    if (this.inputOtion.autostorage) {
      this.inputvalue = window.localStorage.getItem(this.inputOtion.storagename)
    }
  },
  methods: {
    clearvalue() {
      this.inputvalue = ''
      if (this.inputOtion.autostorage) {
        window.localStorage.setItem(this.inputOtion.storagename, this.inputvalue)
      }
    },
    changeEvent() {
      this.$emit('input', this.inputvalue)
      if (this.inputOtion.autostorage) {
        window.localStorage.setItem(this.inputOtion.storagename, this.inputvalue)
      }
    },
    changeValue: function (value) {
      this.inputvalue = value
    },
  },
  watch: {
    inputvalue: function (val) {
      this.inputvalue = val.replace(/[^\u4E00-\u9FA5|\d|a-zA-Z|\r\n\s,.?!，。？！…—&$=()-+/*{}[\]]|\s/g, '')
    },
  },
}
</script>

<style scoped>
.input_co_type {
  background: white;
  display: flex;
  border: 1px solid #dddddd;
}
.input_type {
  border: none;
  font-size: 16px;
  color: #999999;
  letter-spacing: 0;
  flex: 1;
  outline: none;
}
.inputicon {
  margin-left: 15px;
}
.inputicon > img {
  position: relative;
  width: 16px;
  height: 16px;
  margin-top: 12px;
  margin-right: 15px;
}
</style>
