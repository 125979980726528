<template>
  <div class="container">
    <div class="login-container" :style="{ backgroundImage: `${loginBg}` }">
      <div class="login-wrap">
        <img :src="loginImg" />
        <div class="login-form">
          <!-- <img :src="qrCodeImg" class="login-form-qrcode" /> -->
          <el-tabs v-model="tabActiveName">
            <el-tab-pane label="验证码登录" name="mobileLogin">
              <div class="alert" v-if="alertText">
                <div class="icon" />
                {{ alertText }}
              </div>
              <input-icon :input-otion="mobileInputOption" style="margin: 20px auto 10px auto" v-model="mobile" />
              <el-row type="flex">
                <input-icon :input-otion="verifyCodeInputOption" style="margin: 10px 0 10px 40px" v-model="captcha" />
                <div class="button send-verifycode-button" @click="getCaptcha" v-if="!timer">{{ sendCaptchaText }}</div>
                <div class="button send-verifycode-button disabled" v-else>{{ count }}秒后重新获取</div>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="密码登录" name="passWordLogin">
              <div class="alert" v-if="alertText">
                <div class="icon" />
                {{ alertText }}
              </div>
              <input-icon :input-otion="userInputOption" style="margin: 20px auto 10px auto" v-model="account" />
              <input-icon :input-otion="passInputOption" style="margin: 20px auto 10px auto" v-model="password" />
            </el-tab-pane>
          </el-tabs>
          <div class="font-s16" style="width: 80%; margin: 10px auto" v-if="tabActiveName === 'passWordLogin'">
            <span @click="$router.push('/set-password')" style="cursor: pointer">忘记密码</span>
          </div>
          <div class="button-primary font-s20 login-button" @click="login">登录</div>
          <div class="guest-login" @click="guestLogin">
            <div class="fuck-img" />
            <div>游客登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import inputwithicon from '@/components/inputwithicon'
import userIcon from '@/assets/images/user.png'
import passwordIcon from '@/assets/images/password.png'
import mobileIcon from '@/assets/images/mobile.png'
import { service } from '@/utils/axios'
import store from '@/store'
export default {
  data() {
    return {
      loginBg: 'url(' + require('../../assets/images/login/login_bg.png') + ')',
      loginImg: require('../../assets/images/login/login_img.png'),
      qrCodeImg: require('../../assets/images/login/qrcode.png'),
      placeHolderTxt: '请输入手机号',
      tabActiveName: 'mobileLogin',
      userInputOption: {
        placeHolderTxt: '请输入手机号码',
        iconUrl: userIcon,
        autoStorage: false,
        storageName: 'username',
        inputWidth: '80%',
        inputHeight: '45px',
      },
      mobileInputOption: {
        placeHolderTxt: '请输入手机号码',
        iconUrl: mobileIcon,
        autoStorage: false,
        storageName: 'mobile',
        inputWidth: '80%',
        inputHeight: '45px',
      },
      passInputOption: {
        placeHolderTxt: '请输入登录密码',
        iconUrl: passwordIcon,
        autoStorage: false,
        storageName: 'password',
        inputWidth: '80%',
        inputHeight: '45px',
        type: 'password',
        maxlength: 16,
      },
      verifyCodeInputOption: {
        placeHolderTxt: '请输入验证码',
        iconUrl: passwordIcon,
        autoStorage: false,
        storageName: 'verifyCode',
        inputWidth: '45%',
        inputHeight: '45px',
        maxlength: 6,
      },
      // input绑定
      mobile: '',
      captcha: '',
      account: '',
      password: '',
      // 计时器
      timer: null,
      // 剩余时间
      count: 0,
      // 默认时间
      defaultCount: 60,
      sendCaptchaText: '发送验证码',
      alertText: '',
    }
  },
  components: {
    'input-icon': inputwithicon,
  },
  methods: {
    // 获取验证码
    async getCaptcha() {
      if (this.timer) {
        return
      }
      if (!this.mobile) {
        this.alertText = '手机号为空'
        return
      }
      if (!/^1[3-9]\d{9}$/.test(this.mobile)) {
        this.alertText = '手机号格式错误'
        return
      }
      const res = await service.post('/app/send_verify_code', { tel: this.mobile })
      if (res.data.ret_code === 0) {
        this.count = this.defaultCount
        this.timer = window.setInterval(() => {
          if (this.count > 0 && this.count <= this.defaultCount) {
            this.count--
          } else {
            this.sendCaptchaText = '重新发送'
            this.timer = null
            window.clearInterval(this.timer)
          }
        }, 1000)
      }
    },
    // 登录
    async login() {
      let next
      if (this.tabActiveName === 'mobileLogin') {
        // 手机号登录
        if (!this.mobile) {
          this.alertText = '手机号为空'
          return
        }
        if (!/^1[3-9]\d{9}$/.test(this.mobile)) {
          this.alertText = '手机号格式错误'
          return
        }
        if (!this.captcha) {
          this.alertText = '验证码为空'
          return
        }
        if (!/^\d{6}$/.test(this.captcha)) {
          this.alertText = '验证码格式错误'
          return
        }
        next = await store.dispatch('user/loginByVerify', { tel: this.mobile, verify: this.captcha })
      } else {
        // 账号登录
        if (!this.account) {
          this.alertText = '用户名为空'
          return
        }
        if (!this.password) {
          this.alertText = '密码为空'
          return
        }
        next = await store.dispatch('user/loginByPwd', { tel: this.account, password: this.password })
      }
      if (next.err) {
        this.alertText = next.err
        return
      }
      next && this.$router.replace(next)
    },
    async guestLogin() {
      const next = await store.dispatch('user/loginByPwd', { tel: 'youke', password: 'youke' })
      next && this.$router.replace(next)
    },
    keyLogin(e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
  },
  computed: {
    formValue() {
      return {
        mobile: this.mobile,
        captcha: this.captcha,
        account: this.account,
        password: this.password,
        tabActiveName: this.tabActiveName,
      }
    },
  },
  watch: {
    formValue() {
      this.alertText = ''
    },
  },
  mounted() {
    document.addEventListener('keyup', this.keyLogin)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.keyLogin)
  },
}
</script>
<style lang="less" scoped>
::v-deep .el-tabs__item {
  font-size: 18px;
  line-height: 18px;
}
.login-container {
  width: 100%;
  height: 650px;
  margin: auto;
  position: relative;
  .login-wrap {
    box-sizing: border-box;
    width: 1200px;
    padding: 20px;
    height: 500px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    img {
      width: 686px;
      height: 386px;
    }
    .login-form {
      width: 400px;
      height: 430px;
      background-color: #ffffff;
      position: relative;
      &-qrcode {
        width: 52px;
        height: 52px;
        position: absolute;
        right: 2px;
        top: 2px;
      }
      .login-button {
        width: 80%;
        height: 50px;
        line-height: 50px;
        margin: 20px auto 0;
        cursor: pointer;
      }
      .send-verifycode-button {
        width: 125px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        justify-content: flex-end;
        align-items: flex-end;
        margin-left: 10px;
        margin-top: 10px;
        color: #fe801a;
        font-size: 16px;
        cursor: pointer;
      }
      .disabled {
        cursor: default;
        background: #fff;
        border: 1px solid #999;
        color: #999;
      }
    }
  }
}
/deep/ .el-tabs__nav {
  margin: 20px auto 0 30px;
}
.guest-login {
  margin-left: -15px;
  margin-top: 21px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fe801a;
  cursor: pointer;
  .fuck-img {
    width: 26px;
    height: 26px;
    background-image: url('../../assets/images/login/user.png');
    margin-right: 10px;
  }
}
.alert {
  width: 320px;
  background: #fff0e4;
  border: 1px solid #fed1ad;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 34px;
  color: #fe801a;
  margin: 0 auto -5px;
  display: flex;
  align-items: center;
  .icon {
    margin-left: 8px;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    background: url('../../assets/images/login/disabled.png') no-repeat;
    background-size: contain;
  }
}
</style>
